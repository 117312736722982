import type {BadgeProps} from '@mantine/core';
import {Badge} from '@mantine/core';

import type {NodeFlag} from '@rockawayxlabs/observatory-database';

type NodeFlagBadgeProps = {
  flag: NodeFlag;
} & BadgeProps;

const colorsMap: Record<NodeFlag, string> = {
  ARCHIVE: 'gray',
  RELAYER: 'blue',
  RPC: 'grape',
  SEED: 'lime',
  // TODO add SENSOR: 'teal' once available
  SENTRY: 'yellow',
  VALIDATOR: 'red',
};

export function NodeFlagBadge({flag, ...props}: NodeFlagBadgeProps) {
  return (
    <Badge variant="light" color={colorsMap[flag]} {...props}>
      {flag}
    </Badge>
  );
}
