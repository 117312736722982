import type {FlexProps} from '@mantine/core';
import {Flex} from '@mantine/core';
import {useMemo} from 'react';

import type {NodeFlag} from '@rockawayxlabs/observatory-database';

import {NodeFlagBadge} from '~/components/NodeFlags/NodeFlagBadge';

type NodeFlagsProps = {
  flags: NodeFlag[];
} & FlexProps;

export function NodeFlags({flags, ...props}: NodeFlagsProps) {
  const filteredFlags = useMemo(() => flags.filter(flag => flag !== 'RELAYER').sort(), [flags]);

  if (!filteredFlags.length) {
    return null;
  }

  return (
    <Flex gap="xs" wrap="wrap" {...props}>
      {filteredFlags.map(flag => (
        <NodeFlagBadge key={flag} flag={flag} />
      ))}
    </Flex>
  );
}
